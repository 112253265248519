import {Component, Input} from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [MatProgressSpinner],
})
export class LoaderComponent {
    @Input() public diameter = 90;
    @Input() public strokeWidth = 3;
}
